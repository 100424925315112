export const PLAYER_EL = 'player-root'

export const ADDITIONAL_CHOICES = 'ADDITIONAL_CHOICES'

export const RESPONSIVE_BREAK_POINTS = {
  mobile: '(max-width: 1025px)',
  desktop: '(min-width: 1025px)',
}

export const CAMERAS = {
  main: 'main',
  monogram: 'monogram',
}

export const SUITCASE_STATES = {
  open: 'open',
  closed: 'closed',
  animating: 'animating',
}

export const PRODUCTS = {
  'soft-bag': 'soft-bag',
  suitcase: 'suitcase',
}

export const DEFAULT_PLAYER_CONFIG = {}
