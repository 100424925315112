const capitalize = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
    .join(' ')

export const getParamsFromUrl = (url) => {
  var query = url.substr(1)
  var result = {}
  query.split('&').forEach((part) => {
    var item = part.split('=')
    result[item[0]] = decodeURIComponent(item[1])
  })
  return result
}

export function dataURItoBlob(dataURI) {
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export const configurationToCart = (config) => {
  if (!config) return {}

  const map = {
    front: 'Front Shell',
    back: 'Back Shell',
    zipper: 'Zipper',
    monogram: 'Monogram Patch',
    binding: 'Binding',
    wheels: 'Wheel Hubcaps',
    handle: 'Carry-handle',
  }

  return Object.entries(map).reduce((output, [key, val]) => {
    if (key in config) output[val] = config[key].toUpperCase()
    return output
  }, {})
}

export const presetToConfiguration = (preset) => {
  if (!preset) return {}

  const colorMap = {
    pur: 'Arabian Purple',
    blu: 'Como Blue',
  }

  const keysMap = {
    'FRONT-SHELL': 'front',
    'BACK-SHELL': 'back',
    HUBCAP: 'wheels',
    PATCH: 'monograb',
    ZIPPER: 'zipper',
    'ZIPPER-BINDING': 'binding',
    'CARRY-HANDLE': 'handle',
    // HANDLEROD: 'handle',
    // STITCH: "RIO FIRE",
    // 'ZIP-PULL': "KYOTO BLACK",
  }

  return Object.entries(keysMap).reduce((output, [key, val]) => {
    if (key in preset)
      output[val] =
        colorMap[preset[key].toLowerCase()] || capitalize(preset[key])
    return output
  }, {})
}

export const getCookie = (cname) => {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const regularToKebabCase = (str) =>
  !str?.length
    ? ''
    : str
        .split(' ')
        .filter((word) => word?.length)
        .map((word) => word.trim().toLowerCase())
        .join('-')
