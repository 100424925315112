import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'

export const Wrapper = styled.div`
  opacity: ${(props) => props.opacity};
  transition: all 0.4s;
  background: white;

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    width: max-content;
    margin: auto;
  }
`

export const AdditionalChoiceWrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 3px;
  margin-bottom: 16px;

  & > div:nth-child(2) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`
