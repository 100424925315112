import React from 'react'
import { RESPONSIVE_BREAK_POINTS as breakPoints } from '../constants'
import { useMediaQuery } from 'react-responsive'
import { createPortal } from 'react-dom'

import HeaderComponent from '../Components/Header'
import ConfiguratorComponent from '../Components/Configurator'
import PlayerComponent from '../Components/Player'
import NavigationComponent from '../Components/Navigation'

//  Hooks
import { useStep } from '../store/hooks'

const Header = () => {
  const [step, totalSteps] = useStep()
  return createPortal(
    <HeaderComponent step={step} total={totalSteps} />,
    document.getElementById('tk-header')
  )
}

const Configurator = () => {
  const isMobile = useMediaQuery({ query: breakPoints.mobile })

  return createPortal(
    <ConfiguratorComponent />,
    document.getElementById(
      isMobile ? 'tk-configurator-mobile' : 'tk-configurator-desktop'
    )
  )
}

const Navigation = () => {
  const [step, totalSteps, stepForward, stepBackward] = useStep()
  const isMobile = useMediaQuery({
    query: breakPoints.mobile,
  })

  return createPortal(
    <NavigationComponent
      step={step}
      totalSteps={totalSteps}
      stepForward={stepForward}
      stepBackward={stepBackward}
    />,
    document.getElementById(
      isMobile ? 'tk-navigation-mobile' : 'tk-navigation-desktop'
    )
  )
}

const Player = () => {
  const isMobile = useMediaQuery({
    query: breakPoints.mobile,
  })

  return createPortal(
    <PlayerComponent />,
    document.getElementById(isMobile ? 'tk-player-mobile' : 'tk-player-desktop')
  )
}

const ConfiguratorPage = () => {
  return (
    <>
      <Header />
      <Configurator />
      <Navigation />
      <Player />
    </>
  )
}

export default ConfiguratorPage
