import {
  getStep,
  getTotalStep,
  setStep,
  getAttributes,
  setConfiguration,
} from './threekit'
import { useSelector, useDispatch } from 'react-redux'
import { THREEKIT_ENV } from './threekit'
import { dataURItoBlob } from '../utils'
import axios from 'axios'

const handleAddToCart = async (variantArray) => {
  if (variantArray) {
    window.threekitVariants = variantArray
  }
  const cartVariant =
    variantArray || window.threekitVariants
      ? threekitVariants.find(
          (variant) =>
            variant.option1 === 'Custom' && variant.option2 === 'Custom'
        )
      : '0123456789'

  if (cartVariant) {
    const config = window.threekit.configurator
      .getDisplayAttributes()
      .reduce((output, attr) => {
        const selected = attr.values.find(
          (el) => el.assetId === attr.value?.assetId
        )
        return Object.assign(output, {
          [attr.name]: selected?.name.toUpperCase(),
        })
      }, {})

    const base64_small = await window.threekit.player.snapshotAsync({
      size: { width: 400, height: 400 },
    })
    const base64_large = await window.threekit.player.snapshotAsync({
      size: { width: 1200, height: 1200 },
    })

    const env = THREEKIT_ENV.split('.')[0]

    const blob_small = dataURItoBlob(base64_small)
    const blob_large = dataURItoBlob(base64_large)
    const fd = new FormData()
    const file_small = new File([blob_small], 'roam_luggage.png')
    fd.append('files', file_small)
    fd.append('env', env)
    const fd_large = new FormData()
    const file_large = new File([blob_large], 'roam_luggage_big.png')
    fd_large.append('files', file_large)
    fd_large.append('env', env)

    const API_URL = 'https://roam-upload-proxy.herokuapp.com/single'

    const { data: thumbnailSmallUrl } = await axios.post(API_URL, fd, {
      'content-type': `multipart/form-data; boundary=${fd._boundary}`,
    })
    const { data: thumbnailLargeUrl } = await axios.post(API_URL, fd_large, {
      'content-type': `multipart/form-data; boundary=${fd._boundary}`,
    })

    await axios.post('/cart/add.js', {
      items: [
        {
          quantity: 1,
          id: cartVariant.id,
          properties: Object.assign({}, config, {
            _tk_thumbnail_small: thumbnailSmallUrl,
            _tk_thumbnail_large: thumbnailLargeUrl,
          }),
        },
      ],
    })
    window.location.href = '/cart'
  }
}

export const useStep = () => {
  const step = useSelector(getStep)
  const totalSteps = useSelector(getTotalStep)
  const dispatch = useDispatch()

  const stepBack = () => {
    if (step === 1)
      return (window.location.href =
        'https://roamluggage.com/collections/all-luggage')

    dispatch(setStep(step - 1))
  }

  const stepForward = () => {
    if (step >= totalSteps) return handleAddToCart()

    dispatch(setStep(step + 1))
  }

  return [step, totalSteps, stepForward, stepBack]
}

export const useAttribute = (attributeName) => {
  if (!attributeName) return [undefined, undefined]
  const dispatch = useDispatch()
  const attribute = useSelector(getAttributes(attributeName))

  if (!attributeName) return [undefined, undefined]
  if (!attribute) return [undefined, undefined]

  const handleChange = (value) => {
    let preppedValue = value
    if (attribute.type === 'Asset') preppedValue = { assetId: value }
    dispatch(setConfiguration({ [attributeName]: preppedValue }))
  }

  return [attribute, handleChange]
}
