import React, { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { AppContext } from '../../context'
import { Wrapper, InputWrapper } from './configurator.styles'
import { ADDITIONAL_CHOICES, RESPONSIVE_BREAK_POINTS } from '../../constants'
import { useMediaQuery } from 'react-responsive'
import { getStepData } from '../../store/threekit'
import { useStep } from '../../store/hooks'

import ColorSelector from '../Inputs/ColorSelector'
import MonogramEditor from '../Inputs/MonogramEditor'
import MultiColorSelector from '../Inputs/MultiColorSelector'

const AdditionalChoices = () => {
  const isMobile = useMediaQuery({ query: RESPONSIVE_BREAK_POINTS.mobile })
  return (
    <Wrapper>
      <div>
        {isMobile
          ? 'Change the color of any design element'
          : 'WHAT DO YOU WANT TO CHANGE?'}
      </div>
      <div>
        <MultiColorSelector />
      </div>
    </Wrapper>
  )
}

const Configurator = () => {
  // const { configurator, activeSection, configuration, setAttribute } =
  //   useContext(AppContext)
  const [step, totalSteps] = useStep()
  const stepData = useSelector(getStepData)
  const [currentSection, setCurrentSection] = useState(step)
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    ;(() => {
      setOpacity(0)
      setTimeout(() => {
        setCurrentSection(step)
        setTimeout(() => {
          setOpacity(1)
        }, 0.1 * 1000)
      }, 0.4 * 1000)
    })()
  }, [step])

  const isMobile = useMediaQuery({ query: RESPONSIVE_BREAK_POINTS.mobile })

  // const section = configurator[currentSection]

  // if (currentSection === ADDITIONAL_CHOICES) return <AdditionalChoices />

  return (
    <Wrapper opacity={opacity}>
      <div>
        Step{' '}
        {isMobile ? (
          <>
            {currentSection + 1} of {totalSteps + 1}:
            <br />
          </>
        ) : (
          `${currentSection + 1}: `
        )}
        {stepData.label}
        {isMobile && '.'}
      </div>
      <div>
        <InputWrapper>
          {stepData?.inputs.map(({ attribute }, i) => (
            <ColorSelector key={i} attribute={attribute} />
          ))}
        </InputWrapper>
      </div>
    </Wrapper>
  )
}

export default Configurator
