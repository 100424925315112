import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'

export const Wrapper = styled.div`
  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    width: calc(100vw - 40px);
  }

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    width: 450px;
  }

  & > div:nth-child(1) {
    color: #000000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;

    @media ${RESPONSIVE_BREAK_POINTS.desktop} {
      margin-bottom: 10px;
    }
  }

  & > div:nth-child(4) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
`

export const Accordian = styled.div`
  height: max-content;
  background: white;
  border: 1.13px solid #000;
  width: 100%;
  margin-bottom: 15px;

  hr {
    background: ${(props) => (props.selected ? 'black' : 'white')};
    height: 1px;
    outline: none;
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  & > div:nth-child(1) {
    min-height: 45px;
    padding: 0 15px;
    border-radius: 3px;
    cursor: pointer;

    display: grid;
    grid-template-columns: auto max-content max-content;
    grid-gap: 10px;

    & > div:nth-child(1) {
      height: max-content;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    & > div:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      color: #333;
      font-family: 'Nunito Sans', sans-serif;
    }

    & > div:nth-child(2) {
      height: 45px;

      & > div {
        height: max-content;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  & > div:nth-child(2) {
    max-height: ${(props) => (props.selected ? '500px' : 0)};
    transition: all 0.3s;
    overflow-y: scroll;

    & > div {
      padding: 0 15px;
    }
  }

  ul {
    padding-left: 0px;
    font-size: 14px;
    font-family: 'Nunito Sans';
    color: #333;
  }
`

export const SuitcaseItemWrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  padding: 10px 0;
  cursor: pointer;

  img {
    height: 30px;
    object-fit: contain;
    padding: 0 8px;
  }

  & > div:nth-child(1) {
    display: grid;
    grid-template-columns: max-content auto max-content;
    grid-gap: 10px;
    margin-bottom: 12px;

    & > div {
      height: max-content;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    & > div:nth-child(3) {
      color: #000000;
      font-family: 'Work Sans';
      font-size: 14px;
      font-weight: bold;
    }

    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      color: #111111;

      span {
        margin-left: 5px;
        color: #918e8e;
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 17px;
        vertical-align: middle;
        font-weight: bold;
      }
    }
  }

  & > div:nth-child(2) {
    font-size: 15px;
    line-height: 24px;
    color: black;
    font-weight: 200;

    span {
      font-weight: 800;
      margin-right: 3px;
    }
  }
`

export const Button = styled.div`
  background-color: white;
  border: 2px solid black;
  color: black;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-align: center;
  cursor: pointer;

  & > div {
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`
