export const jaunt = {
  id: 'the-jaunt',
  title: 'The Jaunt',
  subtitle: 'Carry-On',
  price: null,
  imgUrl:
    'https://cdn.shopify.com/s/files/1/2551/4172/t/27/assets/shadow_jaunt.png?v=7199830715407097882',
  properties: {
    Measurements: '22”h x 14”w x 9”d',
    Weight: '6.6 lbs',
    Capacity: '40 Liters',
  },
}

export const jauntXl = {
  id: 'the-jaunt-xl',
  title: 'The Jaunt XL',
  subtitle: 'Wide Carry-On',
  price: null,
  imgUrl:
    'https://cdn.shopify.com/s/files/1/2551/4172/t/27/assets/shadow_jaunt.png?v=7199830715407097882',
  properties: {
    Measurements: '22”h x 15.75”w x 9”d',
    Weight: '7.1 lbs',
    Capacity: '46.5 Liters',
  },
}

export const journey = {
  id: 'the-journey',
  title: 'The Journey',
  subtitle: 'Medium Check-In',
  price: null,
  imgUrl:
    'https://cdn.shopify.com/s/files/1/2551/4172/t/27/assets/shadow_jaunt.png?v=7199830715407097882',
  properties: {
    Measurements: '25.5”h x 18”w x 10”d',
    Weight: '7.1 lbs',
    Capacity: '68.8 Liters',
  },
}

export const expedition = {
  id: 'the-expedition',
  title: 'The Expedition',
  subtitle: 'Large Check-In',
  price: null,
  imgUrl:
    'https://cdn.shopify.com/s/files/1/2551/4172/t/27/assets/shadow_jaunt.png?v=7199830715407097882',
  properties: {
    Measurements: '29.5”h x 20”w x 11”d',
    Weight: '7.1 lbs',
    Capacity: '98.5 Liters',
  },
}
