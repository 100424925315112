import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../../constants'

export const Wrapper = styled.div`
  max-width: 100%;
  background: white;

  & > div:nth-child(1) {
    input {
      max-width: 100%;
      height: 40px;
      /* width: 30px; */
      /* margin-right: 16px; */
      padding: 6px 4px;
      /* text-align: center; */
      letter-spacing: 16px;
      text-transform: uppercase;

      font-size: 22px;
      font-weight: 800;
      border: none;
      outline: none;

      @media ${RESPONSIVE_BREAK_POINTS.mobile} {
        text-align: ${(props) => props.align};
      }
    }
  }

  & > div:nth-child(2) {
    width: max-content;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 25px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    @media ${RESPONSIVE_BREAK_POINTS.mobile} {
      text-align: ${(props) => props.align};
      width: 100%;
    }
  }
`
