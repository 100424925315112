import React from 'react'
import axios from 'axios'
import {
  dataURItoBlob,
  configurationToCart,
  presetToConfiguration,
} from './utils'
import { CAMERAS, SUITCASE_STATES, PLAYER_EL, PRODUCTS } from './constants'
import { initThreekit } from './Configurator/index'
import { getParamsFromUrl } from './utils'

//  data
import softBagsConfiguratorJSON from './data/soft-bags-configurator.json'
import suitcaseConfiguratorJSON from './data/roam-luggage-configurator.json'
import colorsList from './data/color_options.json'
import colorOptions from './data/color_selection_options.json'
import additionalChoices from './data/additional_choices.json'
import subattributesMap from './data/subattributes_map.json'

export const AppContext = React.createContext(null)

const isSoftBag = window.location.pathname.includes('downtown')

let configuratorJSON = isSoftBag
  ? softBagsConfiguratorJSON
  : suitcaseConfiguratorJSON
class AppProvider extends React.Component {
  size = isSoftBag
    ? PRODUCTS['soft-bag']
    : window.location.pathname.split('/products/')[1] &&
      Object.keys(colorOptions).includes(
        window.location.pathname.split('/products/')[1]
      )
    ? window.location.pathname.split('/products/')[1]
    : window.threekitVariants?.[0].sku || Object.keys(colorOptions)[0]

  handleSetSection = (section) => this.setState({ activeSection: section })

  setAttribute = (attr, value) => {
    const config = { [attr]: value }
    if (subattributesMap[attr])
      subattributesMap[attr].forEach((subattr) => (config[subattr] = value))

    this.setState((state) => ({
      configuration: { ...state.configuration, ...config },
    }))
    if (this.state.configApi) this.state.configApi.setConfiguration(config)
  }

  handleAddToCart = async (variantArray) => {
    if (variantArray) {
      window.threekitVariants = variantArray
    }
    const cartVariant =
      variantArray || window.threekitVariants
        ? threekitVariants.find(
            (variant) =>
              variant.option1 === 'Custom' && variant.option2 === 'Custom'
          )
        : '0123456789'

    if (cartVariant) {
      const config = { ...this.state.configuration }
      delete config.size

      await configApi.switchToMainCamera()
      const base64_small = await configApi.getSnapshot({
        size: { width: 400, height: 400 },
      })
      const base64_large = await configApi.getSnapshot({
        size: { width: 1200, height: 1200 },
      })

      const env = configApi.getTkEnvHostName()

      const blob_small = dataURItoBlob(base64_small)
      const blob_large = dataURItoBlob(base64_large)
      const fd = new FormData()
      const file_small = new File([blob_small], 'roam_luggage.png')
      fd.append('files', file_small)
      fd.append('env', env)
      const fd_large = new FormData()
      const file_large = new File([blob_large], 'roam_luggage_big.png')
      fd_large.append('files', file_large)
      fd_large.append('env', env)

      const API_URL = 'https://roam-upload-proxy.herokuapp.com/single'

      const { data: thumbnailSmallUrl } = await axios.post(API_URL, fd, {
        'content-type': `multipart/form-data; boundary=${fd._boundary}`,
      })
      const { data: thumbnailLargeUrl } = await axios.post(API_URL, fd_large, {
        'content-type': `multipart/form-data; boundary=${fd._boundary}`,
      })

      await axios.post('/cart/add.js', {
        items: [
          {
            quantity: 1,
            id: cartVariant.id,
            properties: {
              ...configurationToCart(config),
              Monogram: this.state.monogram.toString().toUpperCase(),
              _tk_thumbnail_small: thumbnailSmallUrl,
              _tk_thumbnail_large: thumbnailLargeUrl,
            },
          },
        ],
      })
      window.location.href = '/cart'
    }
  }

  handleSwitchCamera = async (name) => {
    if (!this.state.configApi) return

    if (name) {
      if (name === CAMERAS.monogram) {
        this.state.configApi.switchToMonogramCamera()
        this.setState({ activeCamera: CAMERAS.monogram })
      } else {
        this.state.configApi.switchToMainCamera()
        this.setState({ activeCamera: CAMERAS.main })
      }
      return
    }

    if (this.state.activeCamera === CAMERAS.monogram) {
      this.state.configApi.switchToMainCamera()
      this.setState({ activeCamera: CAMERAS.main })
    } else if (this.state.activeCamera === CAMERAS.main) {
      this.state.configApi.switchToMonogramCamera()
      this.setState({ activeCamera: CAMERAS.monogram })
    }
  }

  handleUpdateMonogram = (val) => this.setState({ monogram: val })

  handleAnimateSuitcase = () => {
    if (this.state.configApi) this.state.configApi.playAnimation()
  }

  handleZoom = (val) => {
    if (this.state.configApi) this.state.configApi.zoom(val)
  }

  onSuitcaseStateChange = (suitcaseState) => {
    this.setState({ suitcaseState: SUITCASE_STATES[suitcaseState] })
  }

  onSizeStateChange = (renderState) =>
    this.setState({ rendered: renderState === 'start' ? false : true })

  state = {
    product: isSoftBag ? PRODUCTS['soft-bag'] : PRODUCTS.suitcase,
    suitcaseState: SUITCASE_STATES.closed,
    rendered: false,
    activeCamera: CAMERAS.main,
    activeSection: 0,
    additionalChoices: additionalChoices.map((el) => ({
      ...el,
      attr: el.options,
      options: (colorOptions[this.size][el.options] || []).map(
        (color) => colorsList[color]
      ),
    })),
    configurator: configuratorJSON.map((section) => ({
      ...section,
      inputs: !section.inputs
        ? []
        : section.inputs.map((input) =>
            input.type === 'color-selector'
              ? {
                  ...input,
                  attr: input.data.options,
                  data: {
                    ...input.data,
                    options: (input.data.options === 'all'
                      ? colorOptions.all
                      : colorOptions[this.size][input.data.options]
                    ).map((color) => colorsList[color]),
                  },
                }
              : input
          ),
    })),
    handleSetSection: this.handleSetSection,
    handleAddToCart: this.handleAddToCart,
    handleSwitchCamera: this.handleSwitchCamera,
    setAttribute: this.setAttribute,
    handleUpdateMonogram: this.handleUpdateMonogram,
    handleAnimateSuitcase: this.handleAnimateSuitcase,
    handleZoom: this.handleZoom,
    monogram: '',
    configuration: Object.entries(colorOptions[this.size] || {}).reduce(
      (config, [key, [val]]) => {
        const { label } = colorsList[val]
        config[key] = label
        return config
      },
      { size: this.size }
    ),
    initialize: () => {
      initThreekit(
        this.state.configuration,
        this.onSuitcaseStateChange,
        this.onSizeStateChange,
        PLAYER_EL
      ).then((configApi) => {
        const params = getParamsFromUrl(window.location.search)
        const pageConfig =
          'custom' in params && localStorage.materials
            ? JSON.parse(localStorage.materials).reduce(
                (output, el, i, src) => {
                  if (i === 0 || i % 2 === 0) {
                    output[el] = src[i + 1]
                  }
                  return output
                },
                {}
              )
            : null

        const currentConfig = {
          ...this.state.configuration,
          ...presetToConfiguration(pageConfig),
        }

        process.env.NODE_ENV === 'development' &&
          console.log('Initial Config: ', currentConfig)
        this.setState({
          configApi,
          configuration: currentConfig,
        })
        // delete currentConfig.size;
        configApi.setConfiguration(currentConfig)
      })
    },
  }

  componentDidMount() {}

  componentDidUpdate() {
    process.env.NODE_ENV === 'development' && console.log(this.state)
  }

  render() {
    return (
      <AppContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default AppProvider
