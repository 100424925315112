import React from 'react'
import { RESPONSIVE_BREAK_POINTS } from '../constants'
import { useMediaQuery } from 'react-responsive'
import { PresetsPageWrapper as Wrapper } from './pages.styles'

import Title from '../Components/PresetTitle'
import Configurator from '../Components/PresetConfigurator'
import Player from '../Components/Player'

const PresetsPage = () => {
  const isMobile = useMediaQuery({ query: RESPONSIVE_BREAK_POINTS.mobile })

  if (isMobile)
    return (
      <Wrapper>
        <Title />
        <Player />
        <Configurator />
      </Wrapper>
    )

  return (
    <Wrapper>
      <div>
        <Title />
        <Configurator />
      </div>
      <div>
        <Player />
      </div>
    </Wrapper>
  )
}

export default PresetsPage
