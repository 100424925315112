import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'

export const Wrapper = styled.div`
  text-transform: uppercase;
  font-size: 50px;
  line-height: 64px;
  font-family: 'Flama Condensed';
  font-weight: bold;
  color: black;

  margin-bottom: 20px;

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    font-family: 'Flama Condensed';
    margin-bottom: 0;
    padding: 0px 20px 0px;
    text-align: center;
    font-weight: bold;
    font-size: 38px;
  }
`
