import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'

export const Wrapper = styled.div`
  margin-bottom: 50px;
  background: white;

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    margin-bottom: 0px;
  }

  & > div:nth-child(1) {
    text-align: center;
    padding: 0 0 10px 0;
    font-family: 'Flama Condensed';
    font-size: 48px;
    line-height: 48px;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    line-height: 100px;

    @media ${RESPONSIVE_BREAK_POINTS.mobile} {
      font-size: 36px;
      line-height: 36px;
      padding: 5px 0;
      margin: 0 auto;
      max-width: 263px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    width: max-content;
    margin: 0 auto;
  }
`

export const Step = styled.div`
  height: 40px;
  width: 40px;
  background: ${(props) =>
    props.isComplete || props.isActive ? 'black' : '#C5C5C5'};
  border-radius: 50%;

  text-align: center;
  color: ${(props) =>
    props.isActive ? 'white' : props.isComplete ? 'black' : '#C5c5c5c5'};
  font-size: 28px;

  & > div {
    height: min-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Line = styled.hr`
  height: 2px;
  width: 100px;
  background: ${(props) => (props.isComplete ? 'black' : '#C5C5C5')};
  outline: none;
  border: none;
  margin: auto 0;
`
