import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'

export const Wrapper = styled.div`
  background: white;
  opacity: ${(props) => props.opacity};
  transition: all 0.4s;

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    margin-bottom: 10px;
  }

  & > div:nth-child(1) {
    color: #333;
    font-size: 36px;
    line-height: 1.15;
    font-weight: bold;
    color: black;
    text-align: left;
    padding: 0 0 25px 0;
    font-family: 'Flama Condensed';

    @media ${RESPONSIVE_BREAK_POINTS.mobile} {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      padding: 8px 25px;
      font-family: 'Nunito Sans';
    }
  }
`

export const InputWrapper = styled.div`
  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    max-width: 360px;
  }

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    padding: 10px 25px 5px 25px;
    margin: 0 auto;
    width: max-content;
    max-width: 100%;
  }
`
