import {
  ANIMATION_DEPENDENTS,
  MODEL_NULL,
  MODEL_POSITIONS,
  PIVOT_NULL,
  SHADOW_PLANES,
  SUITCASE_FRONT_NULL,
} from './constants'
import { getItemAssetInstance, getNodeIds } from './helpers'

const TOTAL_DURATION = 1500
const TOTAL_ROTATION = -84.5
const CLOSED_ROTATION = 0
const OPEN_ROTATION = CLOSED_ROTATION + TOTAL_ROTATION
const { x: OPEN_X, z: OPEN_Z } = MODEL_POSITIONS.open

const state = { suitcaseOpen: false, animStateChangeListeners: [] }

export const addAnimStatechangeListener = (listenerFn) =>
  state.animStateChangeListeners.push(listenerFn)

export const initAnimation = async (modelInstanceId, suitcaseInstanceId) => {
  const { api } = window
  state.shadowPlanes = getNodeIds(SHADOW_PLANES, modelInstanceId)

  state.dependentComponents = getNodeIds(
    ANIMATION_DEPENDENTS,
    suitcaseInstanceId
  )

  if (!state.pivotNullId) {
    const from = await getItemAssetInstance(api.instanceId)
    state.modelNullId = api.scene.get({
      from,
      name: MODEL_NULL,
    }).id
    state.pivotNullId = api.scene.get({
      from,
      name: PIVOT_NULL,
    }).id
  }

  state.rotationQuery = {
    name: SUITCASE_FRONT_NULL,
    from: suitcaseInstanceId,
    plug: 'Transform',
    property: 'rotation',
  }

  state.translationQuery = {
    id: state.modelNullId,
    plug: 'Transform',
    property: 'translation',
  }
}

export const playAnimation = async (
  open = !state.suitcaseOpen,
  instantaneous
) => {
  const { api, cancelAnimationFrame, requestAnimationFrame } = window
  state.animStateChangeListeners.forEach((fn) => fn('animating'))

  state.suitcaseOpen = open
  cancelAnimationFrame(state.lastRequestId)

  let start
  let initialRotation
  let initialTranslation
  const direction = open ? -1 : 1
  let toggledInterior = false

  // Restore default model rotation and camera state before playing animation
  api.player.cameraController.restoreInitialCameraState()
  api.scene.set(
    { id: state.pivotNullId, plug: 'Transform', property: 'rotation' },
    { y: 0 }
  )

  api.scene.set(
    {
      id: state.shadowPlanes[`shadowPlane${open ? 'Closed' : 'Open'}`],
      plug: 'Properties',
      property: 'visible',
    },
    false
  )

  api.scene.set(
    {
      id: state.dependentComponents[`hinge${open ? 'Closed' : 'Open'}`],
      plug: 'Properties',
      property: 'visible',
    },
    false
  )

  function step(timestamp) {
    if (start === undefined) start = timestamp
    const elapsed = timestamp - start

    const nodeRotation = api.scene.get(state.rotationQuery)
    if (initialRotation === undefined) initialRotation = nodeRotation

    const nodeTranslation = api.scene.get(state.translationQuery)
    if (initialTranslation === undefined) initialTranslation = nodeTranslation

    nodeRotation.y = instantaneous
      ? open
        ? OPEN_ROTATION
        : CLOSED_ROTATION
      : Math.min(
          Math.max(
            initialRotation.y +
              ((direction * Math.abs(TOTAL_ROTATION)) / TOTAL_DURATION) *
                elapsed,

            OPEN_ROTATION
          ),
          CLOSED_ROTATION
        )

    nodeTranslation.x = instantaneous
      ? open
        ? OPEN_X
        : 0
      : Math.max(
          Math.min(
            initialTranslation.x +
              ((-1 * direction * Math.abs(OPEN_X)) / TOTAL_DURATION) * elapsed,
            OPEN_X
          ),
          0
        )

    nodeTranslation.z = instantaneous
      ? open
        ? OPEN_Z
        : 0
      : Math.min(
          Math.max(
            initialTranslation.z +
              ((direction * Math.abs(OPEN_Z)) / TOTAL_DURATION) * elapsed,
            OPEN_Z
          ),
          0
        )

    if (
      instantaneous ||
      (!toggledInterior &&
        ((open && nodeRotation.y - CLOSED_ROTATION < TOTAL_ROTATION / 4) ||
          (!open && nodeRotation.y - CLOSED_ROTATION > TOTAL_ROTATION / 4)))
    ) {
      toggledInterior = true
      api.scene.set(
        {
          id: state.dependentComponents.interiorMiddleOpen,
          plug: 'Properties',
          property: 'visible',
        },
        open
      )
    }

    api.scene.set(state.rotationQuery, nodeRotation)
    api.scene.set(state.translationQuery, nodeTranslation)
    if (
      !instantaneous &&
      (!elapsed ||
        (nodeRotation.y > OPEN_ROTATION && nodeRotation.y < CLOSED_ROTATION))
    )
      state.lastRequestId = requestAnimationFrame(step)
    else {
      api.scene.set(
        {
          id: state.shadowPlanes[`shadowPlane${open ? 'Open' : 'Closed'}`],
          plug: 'Properties',
          property: 'visible',
        },
        true
      )
      api.scene.set(
        {
          id: state.dependentComponents[`hinge${open ? 'Open' : 'Closed'}`],
          plug: 'Properties',
          property: 'visible',
        },
        true
      )
      state.lastRequestId = null
      state.animStateChangeListeners.forEach((fn) =>
        fn(state.suitcaseOpen ? 'open' : 'closed')
      )
    }
  }

  if (instantaneous) return step()

  state.lastRequestId = requestAnimationFrame(step)
}
