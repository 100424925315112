export const THREEKIT_ENV_HOST_NAME = 'admin-fts'
export const THREEKIT_ENV_HOST = 'https://admin-fts.threekit.com'
export const THREEKIT_ORG_ID = '6bfb5139-e2af-4d24-b18e-de3c6dd9f31c'
export const THREEKIT_AUTH_TOKEN = '5db9ca1c-c699-4278-b39f-33abe89f0803'

export const ASSET_ID = '6ddaeb1d-9b0d-49be-a312-55179646d923'
export const MODEL_NULL = 'Model_Null'
export const PIVOT_NULL = 'Pivot_Null'
export const SUITCASE_FRONT_NULL = 'Front_Null'
export const CAMERAS = {
  main: {
    'the-jaunt': 'Camera',
    'the-jaunt-xl': 'Camera',
    'the-journey': 'Journey_Camera',
    'the-expedition': 'Globetrotter_Camera',
  },
  monogram: {
    'the-jaunt': 'Monogram_Camera',
    'the-jaunt-xl': 'Monogram_Camera',
    'the-journey': 'Monogram_Camera_Journey',
    'the-expedition': 'Monogram_Camera_Globetrotter',
  },
}
export const ANIMATION_DEPENDENTS = {
  interiorMiddleOpen: 'Middle_part_open',
  hingeClosed: 'Hinge_Closed',
  hingeOpen: 'Hinge_open',
}
export const SHADOW_PLANES = {
  shadowPlaneOpen: 'ShadowPlane_Open',
  shadowPlaneClosed: 'ShadowPlane',
}

export const VALUE_MAP = {
  Size: {
    'the-jaunt': 'The Jaunt',
    'the-jaunt-xl': 'The Jaunt XL',
    'the-journey': 'The Journey',
    'the-expedition': 'The Globetrotter',
  },
}
export const ATTR_TO_ASSET_MAP = {
  Size: 'Suitcase',
  FrontShellMat: 'Shell',
  BackShellMat: 'Shell',
  ZipperPullMat: 'Zipper Pull',
  ZipperTapeMat: 'Zipper Tape',
  BindingMat: 'Binding',
  HubcapMat: 'Hubcap',
  CarryHandleMat: 'Carry-Handle',
  MonogramSwatchMat: 'Monogram Swatch',
  HingeMat: 'Hinge',
}
export const ATTR_MAP = {
  size: ['Size'],
  front: ['FrontShellMat'],
  back: ['BackShellMat'],
  zipper: ['ZipperPullMat', 'ZipperTapeMat'],
  binding: ['BindingMat', 'HingeMat'],
  wheels: ['HubcapMat'],
  handle: ['CarryHandleMat'],
  monogram: ['MonogramSwatchMat'],
  monogramText: ['MonogramText'],
}

export const MODEL_POSITIONS = { open: { x: 0.0375, z: -0.03 } }
