import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../../constants'

export const Wrapper = styled.div`
  background: white;
  border: 1px solid black;
  padding: 0 10px;

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    margin: 0 20px;
  }

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    width: 360px;
    margin: 0 25px;
  }
`

export const Section = styled.div`
  overflow: hidden;
  height: max-content;
  ${(props) => !props.last && 'border-bottom: 1px solid black;'}

  & > div {
    padding-right: 20px;
    padding-left: 8px;
  }
  & > div:nth-child(1) {
    height: 48px;
    display: flex;
    justify-content: space-between;
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    & > div {
      height: min-content;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & > div:nth-child(2) {
    transition: all 0.3s;
    max-height: ${(props) => (props.expand ? '300px' : '0px')};

    & > div:nth-child(1) {
      padding: 10px 0;
    }
  }
`
