import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../constants'

export const PresetsPageWrapper = styled.div`
  background: white;

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 20px;
  }
`
