import React, { useState, useContext } from 'react'
import { Wrapper } from './monogramEditor.styles'
import { AppContext } from '../../../context'
import { CAMERAS } from '../../../constants'

const MonogramEditor = ({ onEdit, align }) => {
  const { activeCamera, handleSwitchCamera, monogram, handleUpdateMonogram } =
    useContext(AppContext)

  const handleChange = (val) => {
    if (val.length > 3) return
    handleUpdateMonogram(val)
    onEdit(val)
  }

  return (
    <Wrapper align={align}>
      <div>
        <input
          type="text"
          placeholder="ABC"
          value={monogram}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div onClick={() => handleSwitchCamera()}>
        {activeCamera === CAMERAS.main
          ? 'Preview your monogram tag'
          : 'Preview your suitcase'}
      </div>
    </Wrapper>
  )
}

export default MonogramEditor
