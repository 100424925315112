import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../context'
import {
  Wrapper,
  AdditionalChoiceWrapper,
  ButtonsWrapper,
} from './navigation.styles'
import {
  ADDITIONAL_CHOICES,
  RESPONSIVE_BREAK_POINTS,
  CAMERAS,
  PRODUCTS,
} from '../../constants'
import Button from '../Button'
import { useMediaQuery } from 'react-responsive'

const AdditionalChoiceButton = (props) => {
  const isDesktop = useMediaQuery({ query: RESPONSIVE_BREAK_POINTS.desktop })

  if (!props.show) return null
  return (
    <AdditionalChoiceWrapper>
      <Button onClick={() => handleSetSection(ADDITIONAL_CHOICES)}>
        I'D LIKE TO SEE MORE CHOICES
      </Button>
      {isDesktop && (
        <div>
          <div>(Optional)</div>
        </div>
      )}
    </AdditionalChoiceWrapper>
  )
}

const NavigationComponent = (props) => {
  const { step, totalSteps, handleClickBack, handleClickNext } = props
  return (
    <Wrapper opacity={opacity}>
      <AdditionalChoiceButton show={step === 3} />
      <ButtonsWrapper>
        <Button onClick={handleClickBack}>Back</Button>
        <Button onClick={handleClickNext}>
          {step >= totalSteps ? 'Purchase' : 'Next'}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

const Navigation = (props) => {
  const { step, totalSteps, stepForward, stepBackward } = props
  const [currentStep, setCurrentStep] = useState(step)
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    ;(() => {
      setOpacity(0)
      setTimeout(() => {
        setCurrentStep(step)
        setTimeout(() => {
          setOpacity(1)
        }, 0.1 * 1000)
      }, 0.4 * 1000)
    })()
  }, [step])
  return (
    <Wrapper opacity={opacity}>
      {/* <AdditionalChoiceButton show={step === 3} /> */}
      <ButtonsWrapper>
        {currentStep > 1 && <Button onClick={stepBackward}>Back</Button>}
        <Button onClick={stepForward}>
          {currentStep >= totalSteps ? 'Purchase' : 'Next'}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default Navigation
