import {
  ASSET_ID,
  ATTR_MAP,
  ATTR_TO_ASSET_MAP,
  THREEKIT_AUTH_TOKEN,
  THREEKIT_ENV_HOST_NAME,
  VALUE_MAP,
} from './constants'
import {
  addAnimStatechangeListener,
  initAnimation,
  playAnimation,
} from './animation'
import {
  fetchItems,
  getModelAssetInstance,
  getInstanceIdByAttr,
} from './helpers'
import { initCameras, switchCamera, zoom } from './camera'

const state = { shopifyConfig: {}, renderStateChangeListeners: [] }
window.state = state

let assetMap

const addSizeStateChangeListener = (listenerFn) =>
  state.renderStateChangeListeners.push(listenerFn)

const getConfiguration = () => window.configurator.getConfiguration()

const mapAttribute = (appAttr, appVal) => {
  if (ATTR_MAP[appAttr]) {
    const configuration = ATTR_MAP[appAttr].reduce((config, attr) => {
      const value = (VALUE_MAP[attr] && VALUE_MAP[attr][appVal]) || appVal
      if (!ATTR_TO_ASSET_MAP[attr]) config[attr] = value
      else {
        const assetId = assetMap[ATTR_TO_ASSET_MAP[attr]][value]
        if (assetId) config[attr] = { assetId }
      }
      return config
    }, {})
    return configuration
  }
}

const mapConfiguration = (uiConfig) =>
  Object.entries(uiConfig).reduce(
    (config, [key, value]) => ({ ...config, ...mapAttribute(key, value) }),
    {}
  )

const handleSizeChange = async (size) => {
  const { api } = window
  const modelInstanceId = await getInstanceIdByAttr('Size')
  const suitcaseInstanceId = await getModelAssetInstance(
    api.scene.get({
      name: 'Suitcase',
      from: modelInstanceId,
    }).id
  )

  await Promise.all([
    initCameras(size),
    initAnimation(modelInstanceId, suitcaseInstanceId),
  ])
  switchCamera('main')
  playAnimation(false, true)

  state.renderStateChangeListeners.forEach((fn) => fn('finish'))
}

const setConfiguration = async (config) => {
  const { configurator } = window
  const changedAttrs = Object.keys(config).reduce((acc, attrName) => {
    if (config[attrName] !== state.shopifyConfig[attrName])
      acc[attrName] = config[attrName]
    return acc
  }, {})
  Object.assign(state.shopifyConfig, changedAttrs)

  if (changedAttrs.size) {
    state.renderStateChangeListeners.forEach((fn) => fn('start'))
  }
  const tkConfig = mapConfiguration(changedAttrs)
  await configurator.setConfiguration(tkConfig)
  if (changedAttrs.size) await handleSizeChange(config.size)
}

const createAssetMap = () =>
  fetchItems('app').then(({ assets }) => {
    assetMap = assets.reduce((acc, { id, name }) => {
      const [key, val] = name.split('_')
      if (!acc[key]) acc[key] = {}
      acc[key][val] = id
      return acc
    }, {})
  })

const initPlayer = (initialConfiguration, playerEl) =>
  window
    .threekitPlayer({
      authToken: THREEKIT_AUTH_TOKEN,
      el: document.getElementById(playerEl),
      assetId: ASSET_ID,
      initialConfiguration,
      showLoadingThumbnail: true,
      allowMobileVerticalOrbit: true,
      cache: {
        maxAge: 300,
        scope: 'v2.1',
      },
    })
    .then(async (api) => {
      api.enableApi('player')
      window.api = api
      window.configurator = await api.getConfigurator()
      api.tools.removeTools(['pan', 'zoom'])
      api.tools.setTool('orbit', { options: { turnTableMobileOnly: false } })
      return api.when('loaded')
    })

export const initThreekit = async (
  uiConfig,
  onAnimStateChange,
  onSizeStateChange,
  playerEl
) => {
  await createAssetMap()
  const initialConfig = initialConfig ? mapConfiguration(uiConfig) : {}
  await initPlayer(initialConfig, playerEl)

  addAnimStatechangeListener(onAnimStateChange)
  addSizeStateChangeListener(onSizeStateChange)

  state.renderStateChangeListeners.forEach((fn) => fn('finish'))

  const configApi = {
    getConfiguration,
    setConfiguration,
    switchToMainCamera: () => {
      playAnimation(false, true)
      switchCamera('main')
    },
    switchToMonogramCamera: () => {
      playAnimation(false, true)
      switchCamera('monogram')
    },
    playAnimation,
    zoom,
    getSnapshot: api.snapshotAsync,
    getTkEnvHostName: () => THREEKIT_ENV_HOST_NAME,
  }

  window.configApi = configApi // For dev purposes

  return configApi
}
