import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import threekit from './threekit'

const store = configureStore({
  reducer: {
    threekit,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat(logger),
})

export default store
