import React from 'react'
import { Wrapper, Step, Line } from './header.styles'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'
import { useMediaQuery } from 'react-responsive'

export const Header = (props) => {
  const total = props.total + 1
  const step = props.step

  const isDesktop = useMediaQuery({ query: RESPONSIVE_BREAK_POINTS.desktop })
  return (
    <Wrapper>
      <div>CREATE YOUR DESIGN IN 5 EASY STEPS.</div>
      {isDesktop && (
        <div>
          {new Array(total).fill(null).map((_, i) => (
            <React.Fragment key={i}>
              <Step isActive={i === step} isComplete={i < step}>
                <div>{step + 1}</div>
              </Step>
              {i < total - 1 && <Line isComplete={i < step} />}
            </React.Fragment>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

export default Header
