import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { DEFAULT_PLAYER_CONFIG } from '../constants'

import softBagConfiguratorJSON from '../data/soft-bags-configurator.json'
import toteConfiguratorJSON from '../data/tote-configurator.json'
import cosmeticBagConfiguratorJSON from '../data/cosmetic-bag-configurator.json'

let configuratorJSON = softBagConfiguratorJSON

const assets = {
  downtown: '528f40db-8806-49bb-a872-cec4664cc384',
  metropolitan: 'd33b6e52-2ea1-46fb-8507-82f38f83519e',
  continental: 'a28c03b7-9e7f-4fa1-99b4-d7b561bf0c05',
  tote: '40f39c56-d2ce-4362-850d-7e5951a4bde8',
  cosmeticBagMini: '6c0d70aa-25ab-4349-8990-114fb60e11e6',
  cosmeticBagMidi: '56e0a99f-0b9f-410b-88fe-56eb7b8620fb',
  cosmeticBagMaxi: '114ba7c4-6193-4744-b57c-bd4ce21eaff5',
}

const isDowntown = window.location.pathname.includes('downtown')
const isMetropolitan = window.location.pathname.includes('metropolitan')
const isContinental = window.location.pathname.includes('continental')
const isTote = window.location.pathname.includes('the-shop')
const isCosmeticBagMini = window.location.pathname.includes('flip-flap-mini')
const isCosmeticBagMidi = window.location.pathname.includes('flip-flap-midi')
const isCosmeticBagMaxi =
  window.location.pathname.includes('flip-flap-maxi') ||
  window.location.pathname.includes('the-flip-flap-mix')

if (isDowntown || isMetropolitan || isContinental)
  configuratorJSON = softBagConfiguratorJSON
else if (isTote) configuratorJSON = toteConfiguratorJSON
else if (isCosmeticBagMini || isCosmeticBagMidi || isCosmeticBagMaxi)
  configuratorJSON = cosmeticBagConfiguratorJSON

/*****************************************************
 * CREDENTIALS
 ****************************************************/

export let ASSET_ID =
  process.env.ASSET_ID || 'd33b6e52-2ea1-46fb-8507-82f38f83519e'
export const AUTH_TOKEN =
  process.env.AUTH_TOKEN || '36b201a6-a5d6-456d-a0f9-1e831ddd8530'
export const THREEKIT_ENV = process.env.THREEKIT_ENV || 'preview.threekit.com'
export const ORG_ID =
  process.env.ORG_ID || '52b33d81-c0f3-4f64-960d-4e9286e73af1'

if (isDowntown) ASSET_ID = assets.downtown
else if (isMetropolitan) ASSET_ID = assets.metropolitan
else if (isContinental) ASSET_ID = assets.continental
else if (isTote) ASSET_ID = assets.tote
else if (isCosmeticBagMini) ASSET_ID = assets.cosmeticBagMini
else if (isCosmeticBagMidi) ASSET_ID = assets.cosmeticBagMidi
else if (isCosmeticBagMaxi) ASSET_ID = assets.cosmeticBagMaxi

/*****************************************************
 * UI Helpers
 ****************************************************/

const createThreekitScriptEl = (threekitEnv) => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = `https://${threekitEnv}/app/js/threekit-player-bundle.js`
    script.id = 'threekit-player-bundle'
    script.onload = () => resolve()
    document.head.appendChild(script)
  })
}

const initThreekit = (config) => {
  return new Promise(async (resolve) => {
    const player = await window.threekitPlayer(config)
    const configurator = await player.getConfigurator()
    resolve({ player, configurator })
  })
}

/*****************************************************
 * State
 ****************************************************/

const initialState = {
  //  Tracks Threekit API initialization status
  isThreekitLoaded: false,
  //  Tracks configuration update
  isPlayerLoading: false,
  //  Attributes State
  attributes: undefined,
  //  Steps
  step: 1,
  totalSteps: configuratorJSON.length,
}

const { actions, reducer } = createSlice({
  name: 'threekit',
  initialState,
  reducers: {
    //  Loading Trackers
    setThreekitLoaded: (state, _) => {
      state.isThreekitLoaded = true
      state.isPlayerLoading = true
    },
    setPlayerLoading: (state, action) => {
      state.isPlayerLoading = action.payload
    },
    //  Attributes
    setInternalAttributesState: (state, action) => {
      state.attributes = action.payload.reduce(
        (output, attr) => Object.assign(output, { [attr.name]: attr }),
        {}
      )
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
  },
})

/*****************************************************
 * Actions
 ****************************************************/

//  Actions to be used only internally
const { setInternalAttributesState, setPlayerLoading } = actions

//  Actions to be used only internally and externally
export const { setThreekitLoaded, setStep } = actions

/*****************************************************
 * Standard Selectors
 ****************************************************/

//  Loading Trackers
export const isThreekitLoaded = (state) => state.threekit.isThreekitLoaded

export const isPlayerLoading = (state) => state.threekit.isPlayerLoading

//  Attributes
const getInternalAttributeState = (state) => {
  if (!state.threekit.isThreekitLoaded) return undefined
  return state.threekit.attributes
}

//  Steps
export const getStep = (state) => state.threekit.step

export const getStepData = (state) => configuratorJSON[state.threekit.step - 1]

export const getTotalStep = (state) => state.threekit.totalSteps

/*****************************************************
 * Complex Selectors
 ****************************************************/

//  Attributes
export const getAttributes = (attribute) =>
  createSelector(getInternalAttributeState, (attributes) => {
    if (!attributes) return undefined
    if (!attribute) return attributes
    return attributes[attribute] || undefined
  })

/*****************************************************
 * Complex Actions
 ****************************************************/

export const launch = (config) => async (dispatch) => {
  const launchConfig = Object.assign(
    {},
    DEFAULT_PLAYER_CONFIG,
    Object.keys(DEFAULT_PLAYER_CONFIG).reduce((output, key) => {
      if (config[key] === undefined) return output
      return Object.assign(output, { [key]: config[key] })
    }, {}),
    {
      el: document.getElementById('player-root'),
      assetId: ASSET_ID,
      orgId: ORG_ID,
      authToken: AUTH_TOKEN,
      threekitEnv: THREEKIT_ENV,
      showLoadingThumbnail: true,
      showLoadingProgress: false,
      allowMobileVerticalOrbit: true,
    }
  )

  await createThreekitScriptEl(THREEKIT_ENV)

  const { player, configurator } = await initThreekit(launchConfig)

  window.threekit = {
    player,
    configurator,
  }

  dispatch(setThreekitLoaded(true))
  dispatch(setPlayerLoading(false))

  dispatch(
    setInternalAttributesState(
      window.threekit.configurator.getDisplayAttributes()
    )
  )
}

//  Configurator
export const setConfiguration = (config) => async (dispatch, getState) => {
  dispatch(setPlayerLoading(true))
  await window.threekit.configurator.setConfiguration(config)
  const updatedState = window.threekit.configurator.getDisplayAttributes()
  dispatch(setInternalAttributesState(updatedState))
  dispatch(setPlayerLoading(false))
}

export default reducer
