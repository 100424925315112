import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../../constants'

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(8, max-content); */

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    justify-content: ${(props) =>
      props.align === 'center'
        ? 'center'
        : props.align === 'left'
        ? 'flex-start'
        : props.align === 'right'
        ? 'flex-end'
        : ''};
    width: ${(props) =>
      props.align === 'center'
        ? 'max-content'
        : ['left', 'right'].includes(props.align)
        ? '100%'
        : ''};
    max-width: calc(100vw - (2 * 25px));
  }

  & > div:last-child {
    margin-right: 0px;
  }
`

export const ColorWrapper = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: ${(props) => props.color};

  margin-right: 16px;
  margin-bottom: 25px;

  & > div {
    text-align: center;
    color: white;
    height: min-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`
