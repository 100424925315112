import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Wrapper,
  WidgetsWrapper,
  Zoom,
  Button,
  Loading,
  LoadingAlt,
} from './player.styles'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
// import { SUITCASE_STATES } from '../../constants'
import { Spin } from 'antd'
import { launch, isThreekitLoaded } from '../../store/threekit'

const Player = () => {
  const dispatch = useDispatch()
  const isLoaded = useSelector(isThreekitLoaded)

  useEffect(() => {
    ;(() => dispatch(launch()))()
  }, [])

  // const handleClick = () =>
  //   suitcaseState !== SUITCASE_STATES.animating && handleAnimateSuitcase()

  const handleClickZoomIn = () => window.threekit.player.camera.zoom(1)

  const handleClickZoomOut = () => window.threekit.player.camera.zoom(-1)

  const Widgets = () => {
    return (
      <WidgetsWrapper>
        {/* <Button
          onClick={handleClick}
          disabled={suitcaseState === SUITCASE_STATES.animating}
        >
          <div>
            {suitcaseState === SUITCASE_STATES.closed
              ? 'Look Inside'
              : suitcaseState === SUITCASE_STATES.open
              ? 'Close Suitcase'
              : '...'}
          </div>
        </Button> */}
        <Zoom>
          <div>
            <div onClick={handleClickZoomIn}>
              <div>
                <ZoomInOutlined />
              </div>
            </div>
            <div onClick={handleClickZoomOut}>
              <div>
                <ZoomOutOutlined />
              </div>
            </div>
          </div>
        </Zoom>
      </WidgetsWrapper>
    )
  }

  return (
    <Wrapper>
      {isLoaded ? <Widgets /> : null}
      <div id="player-root" />
      {!isLoaded ? (
        <Loading>
          <LoadingAlt>
            <div></div>
            <Spin size="large" />
          </LoadingAlt>
        </Loading>
      ) : null}
    </Wrapper>
  )
}

export default Player
