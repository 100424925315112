import React from 'react'
import { ConfiguratorPage, PresetPage } from './Pages'
import { getParamsFromUrl } from './utils'

const App = () => {
  const params = getParamsFromUrl(window.location.search)

  if ('custom' in params) return <PresetPage />

  return <ConfiguratorPage />
}

export default App
