import React from 'react'
import { Wrapper } from './presetTitle.styles'
import { getCookie } from '../../utils'

const Header = () => {
  const title = getCookie('gallery_title')
  return <Wrapper>{title || 'Preset Name'}</Wrapper>
}

export default Header
