import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../context'
import { DownOutlined } from '@ant-design/icons'
import {
  Wrapper,
  Accordian,
  SuitcaseItemWrapper,
  Button,
} from './presetConfigurator.styles'
import { CAMERAS } from '../../constants'
import { jaunt, jauntXl, journey, expedition } from '../../data/sizes'
import axios from 'axios'

import MonogramEditor from '../Inputs/MonogramEditor'

import featuresAndDetails from '../../data/features_details.json'

import WarrantandReturns from '../../data/warranty_returns.json'

const sizesObj = [jaunt, jauntXl, journey, expedition].reduce(
  (output, size) => {
    output[size.id] = size
    return output
  },
  {}
)

const SuitcaseItem = ({
  id,
  title,
  price,
  subtitle,
  imgUrl,
  properties,
  setExpandSizes,
}) => {
  const { setAttribute } = useContext(AppContext)

  const handleSetAttribute = () => {
    setAttribute('size', id)
    setExpandSizes(false)
  }

  return (
    <SuitcaseItemWrapper
      onClick={() => {
        handleSetAttribute()
      }}
    >
      <div>
        <div>
          <img src={imgUrl} alt={title} />
        </div>
        <div>
          {title}
          <span>{subtitle}</span>
        </div>
        <div>${price}</div>
      </div>
      <div>
        {properties &&
          Object.entries(properties).map(([key, val], i) => (
            <React.Fragment key={i}>
              <span>{key} </span>
              {val}
              <br />
            </React.Fragment>
          ))}
      </div>
    </SuitcaseItemWrapper>
  )
}

const PresetConfigurator = () => {
  const {
    configuration,
    activeCamera,
    handleAddToCart,
    handleSwitchCamera,
    setAttribute,
  } = useContext(AppContext)
  const [expandSizes, setExpandSizes] = useState(false)
  const [expandFeatures, setExpandFeatures] = useState(false)
  const [expandWarranty, setExpandWarranty] = useState(false)
  const [expandMonogram, setExpandMonogram] = useState(false)
  const [sizes, setSizes] = useState(sizesObj)

  useEffect(() => {
    ;(() => {
      let updatedSizes = {}
      Promise.all(
        Object.values(sizesObj).map((size) => {
          return new Promise(async (resolve) => {
            const { data } = await axios.get(
              `https://roamluggage.com/products/${size.id}.json`
            )
            updatedSizes[size.id] = {
              ...size,
              price: data.product.variants[0].price,
              varientId: data.product.variants,
            }
            resolve()
          })
        })
      ).then(() => setSizes(updatedSizes))
    })()
  }, [])

  const handleClickPurchase = () => {
    handleAddToCart(sizes[configuration.size].varientId)
  }

  const handleClickGallery = () => {
    window.location.href = 'https://roamluggage.com/collections/inspiration'
  }

  const handleClickExpandMonogram = () => {
    handleSwitchCamera(expandMonogram ? CAMERAS.main : CAMERAS.monogram)
    setExpandMonogram(!expandMonogram)
  }

  const handleClickExpandSizes = () => {
    activeCamera !== CAMERAS.main && handleSwitchCamera(CAMERAS.main)
    setExpandSizes(!expandSizes)
  }

  const handleClickExpandFeatures = () => {
    activeCamera !== CAMERAS.main && handleSwitchCamera(CAMERAS.main)
    setExpandFeatures(!expandFeatures)
  }

  const handleClickExpandWarranty = () => {
    activeCamera !== CAMERAS.main && handleSwitchCamera(CAMERAS.main)
    setExpandWarranty(!expandWarranty)
  }

  return (
    <Wrapper>
      <div>Choose a size</div>
      <div>
        <Accordian selected={expandSizes}>
          <div onClick={handleClickExpandSizes}>
            <div>
              <SuitcaseItem
                {...sizes[configuration.size]}
                setExpandSizes={setExpandSizes}
              />
            </div>
            <div style={{ height: '50px' }}>
              <div>
                <DownOutlined />
              </div>
            </div>
          </div>
          <div>
            <div>
              {Object.values(sizes).map((suitcase, i) =>
                suitcase.id === configuration.size ? null : (
                  <React.Fragment key={i}>
                    <hr />
                    <SuitcaseItem
                      {...suitcase}
                      setExpandSizes={setExpandSizes}
                    />
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </Accordian>
      </div>
      <div>
        <Accordian selected={expandFeatures}>
          <div onClick={handleClickExpandFeatures}>
            <div>Features and Details</div>
            <div>
              <div>
                <DownOutlined />
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingLeft: '30px' }}>
              <ul>
                {featuresAndDetails.map((el, i) => (
                  <li key={i}>{el}</li>
                ))}
              </ul>
            </div>
          </div>
        </Accordian>
        <Accordian selected={expandWarranty}>
          <div onClick={handleClickExpandWarranty}>
            <div>Warranty and Return Policy</div>
            <div>
              <div>
                <DownOutlined />
              </div>
            </div>
          </div>
          <div>
            <div>
              {WarrantandReturns.map((el, i) => (
                <p key={i}>
                  {el} <a href="/pages/warranty">Learn more</a>
                </p>
              ))}
            </div>
          </div>
        </Accordian>
        <Accordian selected={expandMonogram}>
          <div onClick={handleClickExpandMonogram}>
            <div>Add a Free Monogram</div>
            <div>
              <div>
                <DownOutlined />
              </div>
            </div>
          </div>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <MonogramEditor
                align="left"
                onEdit={(value) =>
                  setAttribute('monogramText', value.toUpperCase())
                }
              />
            </div>
          </div>
        </Accordian>
      </div>
      <div>
        <Button onClick={handleClickGallery}>
          <div>Back to Gallery</div>
        </Button>
        <Button onClick={handleClickPurchase}>
          <div>Add to Cart</div>
        </Button>
      </div>
    </Wrapper>
  )
}

export default PresetConfigurator
