import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border: 2px solid black;
  color: black;
  border-radius: 0px;
  padding: 16px 50px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  cursor: pointer;
  text-align: center;

  &:hover,
  &:focus {
    background-color: #000;
    color: #fff;
  }
`
