import styled from 'styled-components'
import { RESPONSIVE_BREAK_POINTS } from '../../constants'

const padding = '100px'
const gridGap = '20px'
const configuratorWidth = '450px'

export const Wrapper = styled.div`
  background: white;
  position: relative;
  width: 100%;
  touch-action: none;

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    width: calc(100vw - (${padding} + ${configuratorWidth} + ${gridGap}));
    position: sticky;
    top: 95px;
  }

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    margin-bottom: 25px;
    width: 80vw;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  #player-root {
    height: 500px;
    min-height: 400px;
    max-height: 100vh;
    width: 100%;

    @media ${RESPONSIVE_BREAK_POINTS.mobile} {
      padding-bottom: 45px;
    }

    a {
      display: none;
    }

    &
      > div:nth-child(1)
      > div:nth-child(1)
      > div:nth-child(1)
      > div:nth-of-type(3)
      > div:nth-child(1) {
      display: none;
    }

    &
      > div:nth-child(1)
      > div:nth-child(1)
      > div:nth-child(1)
      > div:nth-of-type(3)
      > div:nth-child(2) {
      display: none;
    }

    @media ${RESPONSIVE_BREAK_POINTS.mobile} {
      min-height: 400px;
      height: 55vh;
    }
  }
`

export const WidgetsWrapper = styled.div`
  position: absolute;
  z-index: 5;
  width: max-content;

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    display: flex;
    grid-gap: 0px;
    gap: 0px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
`

export const Zoom = styled.div`
  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    width: 122px;
    float: right;
  }

  & > div {
    display: flex;
    @media ${RESPONSIVE_BREAK_POINTS.desktop} {
      display: flex;
      flex-direction: column;
    }

    width: max-content;
    margin: 0 auto;

    & > div {
      height: 45px;
      width: 45px;
      margin-bottom: 10px;
      font-size: 22px;
      border-radius: 50%;
      background: #ffffff88;
      border: 1px solid #000;
      cursor: pointer;
      background: white;
      @media ${RESPONSIVE_BREAK_POINTS.mobile} {
        margin-right: 10px;
      }

      & > div {
        height: max-content;
        width: max-content;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
      }

      &:hover {
        ${(props) =>
          !props.disabled &&
          `
      background: #ffffff;
      border: 1px solid #333333;
      color: #333333;
      `}
      }
    }
  }
`

export const Button = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 30px;
  background: #fff;
  border: 1px solid #000;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  width: 45px;
  font-size: 8px;
  line-height: 10px;
  margin: 0 auto;

  @media ${RESPONSIVE_BREAK_POINTS.desktop} {
    margin-bottom: 10px;
  }
  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    margin-right: 10px;
  }

  &:hover {
    background: #ffffff;
    border: 1px solid #333333;
    color: #333333;
  }

  & > div {
    text-align: center;
    position: relative;
    top: 47%;
    transform: translateY(-50%);
  }
`

export const Loading = styled.div`
  width: 100%;
  height: 600px;

  position: absolute;
  top: 0px;
  left: 0px;

  background-color: rgba(255, 255, 255, 0);

  @media ${RESPONSIVE_BREAK_POINTS.mobile} {
    min-height: 400px;
    height: 55vh;
  }
`

export const LoadingAlt = styled.div`
  height: max-content;
  width: max-content;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  z-index: 12;
`
