import React from 'react'
import { Wrapper, ColorWrapper } from './colorSelector.styles'
import { Tooltip } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { useAttribute } from '../../../store/hooks'
import { regularToKebabCase } from '../../../utils'

const Color = ({ color, label, selected, onClick, className }) => (
  <Tooltip placement="top" title={label}>
    <ColorWrapper color={color} onClick={onClick} className={className}>
      {selected && (
        <div>
          <CheckOutlined />
        </div>
      )}
    </ColorWrapper>
  </Tooltip>
)

const ColorSelector = ({ attribute, align = 'center' }) => {
  const [attributeData, setAttribute] = useAttribute(attribute)
  if (!attribute || !attributeData) return null
  return (
    <Wrapper align={align}>
      {attributeData.values.map(({ label, assetId, metadata }, i) => (
        <Color
          className={`custom-attr--${
            attributeData.name
          } custom-attr__opts-box--${regularToKebabCase(label)}`}
          key={i}
          color={metadata.color}
          label={label}
          selected={assetId === attributeData.value.assetId}
          onClick={() => setAttribute(assetId)}
        />
      ))}
    </Wrapper>
  )
}

export default ColorSelector
